import Vue from 'vue'
import SharedConstants from '@/../shared/constants'

export default ({ $config: { WORKING_HOURS_TEXT } }) => {
  Object.keys(SharedConstants).forEach(key => Vue.prototype[`$${key}`] = SharedConstants[key])

  Vue.prototype.$currency_format = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  Vue.prototype.$defaultVariantImage = require('@/assets/images/sn-logo.jpg')
  Vue.prototype.$defaultVariantImageFlipped = require('@/assets/images/sn-logo-flipped.jpg')
  Vue.prototype.$app_name = 'scout'
  Vue.prototype.$phoneMask = '(###) ###-####'
  Vue.prototype.$creditCardMask = '#### #### #### ####'
  Vue.prototype.$workingHoursText = WORKING_HOURS_TEXT
  Vue.prototype.$breadCurrency = 'USD'
}
